import React, { ReactElement } from 'react';
import { Button, PageModal, TickIcon } from '@rentecarlo/component-library';
import styled from 'styled-components';
import { ComponentType, useComponentId, useOptimizelyFlag } from '@hooks';

type InformationItemProps = { title: string; description: string };
interface Props {
  show: boolean;
  onClose: () => void;
  action: () => void;
}

const ModalTitle = styled.h1`
  color: ${({ theme }) => theme.textPrimary};
  font-family: ${({ theme }) => theme.title};
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.8px;
  line-height: 38px;
  margin-bottom: 16px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
`;

const InformationContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const ImageContainer = styled.div`
  height: 23px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  height: 23px;
`;

const Text = styled.p`
  font-family: ${({ theme }) => theme.title};
  font-size: 18px;
  line-height: 23px;
  text-align: left;
  font-weight: bold;
  color: ${({ theme }) => theme.textPrimary};
  margin-bottom: 16px;
`;

const SectionSubTitle = styled.h4`
  color: ${({ theme }) => theme.textPrimary};
  font-family: 'proxima-soft';
  font-weight: normal;
  font-size: 18px;
  line-height: 22.65px;
  margin-top: 8px;
  margin-bottom: 15px;
`;

const ListTitle = styled.h4`
  color: ${({ theme }) => theme.textPrimary};
  font-family: 'proxima-soft';
  font-weight: bold;
  font-size: 14px;
  line-height: 22.65px;
`;

const ListDescription = styled.h4`
  color: ${({ theme }) => theme.textPrimary};
  font-family: 'proxima-soft';
  font-weight: 400;
  font-size: 14px;
  line-height: 22.65px;
`;

const InformationItem = ({ title, description }: InformationItemProps) => {
  const creator = useComponentId();
  return (
    <InformationContainer>
      <ImageContainer>
        <img src={TickIcon} alt='Green Tick Icon' style={{ height: '16px' }} />
      </ImageContainer>
      <ContentContainer>
        <TitleContainer>
          <ListTitle id={creator(ComponentType.TITLE, 'newDriverCrossSellInfoItem')}>
            {title}
          </ListTitle>
        </TitleContainer>
        <ListDescription id={creator(ComponentType.TEXT, 'newDriverCrossSellInfoItem')}>
          {description}
        </ListDescription>
      </ContentContainer>
    </InformationContainer>
  );
};

const NewDriverCrossSellModal = ({ show, onClose, action }: Props): ReactElement => {
  const appTelematicsEnabled = useOptimizelyFlag('TEMP_APP_TELEMATICS_ENABLED').enabled;
  const informationList: InformationItemProps[] = [
    {
      title: "It's a rolling monthly subscription",
      description:
        'Fully comprehensive, rolling insurance cover for as long as you need. You even have the flexibility of cancelling any time with no hidden fees.',
    },
    appTelematicsEnabled
      ? {
          title: 'Download the Veygo app - No black box',
          description:
            'Once you’ve purchased your policy, you’ll download the Veygo app. It’s your all-in-one place to manage your policy and track your trips.',
        }
      : {
          title: 'Download the New Driver telematics app',
          description:
            "Once you've purchased your policy, you'll receive a text message with a link to download and activate our bespoke telematics app.",
        },
    {
      title: 'Get on the road in minutes',
      description:
        "The safer you drive, the less you'll pay. So don't forget to watch your speed and braking and make sure you follow our guidance.",
    },
    {
      title: "We'll monitor your driving",
      description:
        "As we said, the concept is simple - the safer you drive, the less you pay. We'll continually monitor your driving via the telematics app and will re-price your cover every three months. If you have a high driving score, you could see a discount of up to 20% after three months and as much as 30% after nine months.",
    },
  ];

  const creator = useComponentId();
  return (
    <PageModal closeText='Back' close={onClose} show={show}>
      <Container>
        <ModalTitle id={creator(ComponentType.TITLE, 'newDriverCrossSell')}>
          New Driver Insurance
        </ModalTitle>
        <SectionSubTitle id={creator(ComponentType.SUBTITLE, 'newDriverCrossSell')}>
          We know that passing your test and finding out you can't afford car insurance is
          frustrating. That's why we're changing the game for new drivers. We've designed our New
          Driver car insurance so it's fairer, less hassle and commitment free.
        </SectionSubTitle>
        <Text id={creator(ComponentType.TITLE, 'newDriverCrossSellInfo')}>
          New Driver Insurance in a nutshell:
        </Text>
        <ListContainer id={creator(ComponentType.CONTAINER, 'newDriverCrossSellInfoList')}>
          {informationList.map((item) => (
            <InformationItem key={item.title} title={item.title} description={item.description} />
          ))}
        </ListContainer>
      </Container>
      <Button
        id={creator(ComponentType.BUTTON, 'newDriverCrossSellContinue')}
        testId='IWantNewDriverCrossSellButton'
        onClick={action}
      >
        I Want New Driver Insurance
      </Button>
    </PageModal>
  );
};

export default NewDriverCrossSellModal;
