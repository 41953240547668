import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { WrappedFieldProps } from 'redux-form';
import { IconList, TickIcon, ToggleCard } from '@rentecarlo/component-library';

import NewDriverCrossSellModal from '@organisms/modals/NewDriverCrossSellModal';

import { useOptimizelyFlag, useOptimizelyTrack } from '@hooks';

import Arrow from '@assets/arrow-left-icon.svg';
import {
  logNewDriverTelematicsToggledEvent,
  logNewDriverTelematicsModalOpenedEvent,
  logNewDriverTelematicsMessagingEvent,
} from '@services/ecommerceTracking';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 16px;
`;

const Text = styled.p`
  font-size: 14px;
  font-family: ${({ theme }) => theme.standard};
  color: ${({ theme }) => theme.textPrimary};

  font-weight: bold;
  margin: -5px 0;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.textPrimary};
  size: 14px;
  color: ${({ theme }) => theme.linkPrimary};
  font-weight: 700;

  outline: none;
  padding: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;
`;

const Icon = styled.img.attrs({ src: Arrow })`
  margin-left: 8px;
`;

interface TelematicsFieldProps {
  telematicsPrice: string;
  nonTelematicsPrice: string;
}

const TelematicsField: React.FC<WrappedFieldProps & TelematicsFieldProps> = ({
  input: { onChange, value = false },
  nonTelematicsPrice,
  telematicsPrice,
}) => {
  const appTelematicsEnabled = useOptimizelyFlag('TEMP_APP_TELEMATICS_ENABLED').enabled;
  const [showModal, setShowModal] = useState(false);
  const sendEvent = useOptimizelyTrack();

  const priceDifference = parseFloat(nonTelematicsPrice) - parseFloat(telematicsPrice);
  const priceDifferenceText = () =>
    priceDifference >= 10
      ? `Reduce your price by £${String(priceDifference.toFixed(2))} a month`
      : 'Save up to 20% every 3 months';
  useMemo(() => {
    if (nonTelematicsPrice !== '' && telematicsPrice !== '') {
      logNewDriverTelematicsMessagingEvent(priceDifferenceText());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nonTelematicsPrice, telematicsPrice]);

  return (
    <>
      <ToggleCard
        id='productChoice-field-telematics'
        title='Add telematics'
        toggled={value}
        onPress={() => {
          const newValue = !value;
          sendEvent(`newdriver_cross_sell_subs_telematics_toggled_${newValue ? 'on' : 'off'}`);
          logNewDriverTelematicsToggledEvent(newValue);
          onChange(newValue);
        }}
        bannerText={priceDifferenceText()}
      >
        <Container>
          <IconList
            id='productChoice-component-newDriverInfoList'
            icon={TickIcon}
            items={[
              <Text id='productChoice-text-newDriverInfoListOne'>
                Every 3 months, review your price – drive safely and save up to 20%!
              </Text>,
              appTelematicsEnabled ? (
                <Text id='productChoice-text-newDriverInfoListTwo'>
                  Track your driving via the Veygo app on your smartphone - No black box!
                </Text>
              ) : (
                <Text id='productChoice-text-newDriverInfoListTwo'>
                  Track your driving via a separate telematics app on your smartphone - No black
                  box!
                </Text>
              ),
              <Text id='productChoice-text-newDriverInfoListThree'>
                Start building your No Claims Bonus immediately
              </Text>,
            ]}
          />
          <Button
            id='productChoice-button-findOutMore'
            data-testid='find-out-more-button'
            onClick={() => {
              setShowModal(true);
              sendEvent('newdriver_cross_sell_subs_modal_opened');
              logNewDriverTelematicsModalOpenedEvent();
            }}
          >
            Find out more <Icon />
          </Button>
        </Container>
      </ToggleCard>
      <NewDriverCrossSellModal
        show={showModal}
        onClose={() => setShowModal(false)}
        action={() => {
          onChange(true);
          setShowModal(false);
        }}
      />
    </>
  );
};

export default TelematicsField;
