import React from 'react';
import styled from 'styled-components';

import { CardUtils, TickIcon, IconList, Button, Chip } from '@rentecarlo/component-library';

import TelematicsIcon from '@assets/telematics.svg';
import SubscriptionIcon from '@assets/subscription.svg';
import { AlternativeProductTrueResult } from '@redux/reducer/quote/alternativeProducts';
import { useOptimizelyFlag, useOptimizelyTrack } from '@hooks';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Container = styled(CardUtils.StyledCard)`
  border: 1px solid ${({ theme }) => theme.fieldSecondary};
  border-radius: 0 8px 8px;
  padding: 0;
`;

const LabelContainer = styled.div`
  background-color: ${({ theme }) => theme.labelBackground};
  border-radius: 4px 4px 0 0;
  padding: 4px 8px;

  align-self: flex-start;
`;

const LabelText = styled.p`
  color: ${({ theme }) => theme.fieldPrimary};

  font-family: ${({ theme }) => theme.standard};
  font-size: 14px;
  font-weight: bold;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 4px;
  padding: 16px 0;

  border-bottom: 2px solid #16b3b3;
`;

const ChipsContainer = styled.div`
  margin-top: 8px;

  display: flex;
  gap: 16px;
`;

const Title = styled.h3`
  color: ${({ theme }) => theme.textPrimary};

  font-family: ${({ theme }) => theme.standard};
  font-size: 14px;
`;

const SubTitle = styled.p`
  color: ${({ theme }) => theme.textPrimary};

  font-family: ${({ theme }) => theme.standard};
  font-size: 18px;
`;

const Price = styled.span`
  font-size: 32px;
  font-family: ${({ theme }) => theme.title};
  font-weight: bold;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;

  gap: 24px;
  padding: 24px;

  background-color: ${({ theme }) => theme.backgroundSecondary};
  border-radius: 0 0 8px 8px;
`;

const Text = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.textPrimary};

  font-weight: bold;
  margin: -5px 0;
`;

interface Props {
  setNewDriverCrossSellModalIsOpen: (value: boolean) => void;
  newDriverCrossSellResults: AlternativeProductTrueResult;
  isQuoteForMyself: boolean;
}

const NewDriverCard: React.FC<Props> = ({
  setNewDriverCrossSellModalIsOpen,
  newDriverCrossSellResults,
  isQuoteForMyself,
}) => {
  const sendEvent = useOptimizelyTrack();
  const appTelematicsEnabled = useOptimizelyFlag('TEMP_APP_TELEMATICS_ENABLED').enabled;
  return (
    <Wrapper>
      <LabelContainer>
        <LabelText id='rejection-text-newDriverCardSavePercent'>
          Save up to 20% in 3 months
        </LabelText>
      </LabelContainer>
      <Container>
        <TitleContainer>
          <Title id='rejection-title-newDriverCard'>New Driver Insurance</Title>
          <SubTitle id='rejection-subtitle-newDriverCard'>
            <Price id='rejection-text-newDriverCardPrice'>
              £{newDriverCrossSellResults.price.toFixed(2)}
            </Price>{' '}
            a month
          </SubTitle>
          <ChipsContainer>
            <Chip icon={SubscriptionIcon} text='Subscription Policy' />
            <Chip icon={TelematicsIcon} text='Telematics' />
          </ChipsContainer>
        </TitleContainer>
        <Content>
          <IconList
            id='rejection-container-newDriverCardTracking'
            icon={TickIcon}
            items={[
              appTelematicsEnabled ? (
                <Text id='rejection-text-newDriverCardTracking'>
                  Track your driving via the Veygo app on your smartphone - No black box!
                </Text>
              ) : (
                <Text id='rejection-text-newDriverCardTracking'>
                  {isQuoteForMyself
                    ? 'Track your driving via a separate telematics app on your smartphone'
                    : 'They can track their driving via a separate telematics app on their smartphone'}
                </Text>
              ),
              <Text id='rejection-text-newDriverCardPriceReview'>
                Price review every 3 months - the better {isQuoteForMyself ? 'you' : 'they'} drive,
                the more you save
              </Text>,
              <Text id='rejection-text-newDriverCardNCB'>
                Start building {isQuoteForMyself ? 'your' : 'their'} No Claims Bonus immediately
              </Text>,
            ]}
          />
          <Button
            id='rejection-button-newDriverContinue'
            height={40}
            onClick={() => {
              sendEvent('new_driver_rejection_cross_sell_open_modal');
              setNewDriverCrossSellModalIsOpen(true);
            }}
          >
            Continue
          </Button>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default NewDriverCard;
